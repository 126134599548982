.reprioritize-job-sets {
  margin: 1em 0.33em 1em 0.33em !important;
}

.reprioritize-job-sets-options > div {
  margin: 0 1em 0 1em;
}

.reprioritize-job-sets-id {
  width: 40% !important;
}

.reprioritize-job-sets-error {
  width: 60% !important;
}

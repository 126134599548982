.jobs {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.jobs .job-table-header-container {
  flex: 0 0 4em;
}

.jobs .job-table {
  flex: 1 1 auto;
}

.job-submission-time-header-cell {
  font-weight: normal !important;
}

/* Fix table shaking on resize */
.job-table > div,
.ReactVirtualized__Table,
.ReactVirtualized__Table__Grid,
.ReactVirtualized__Table__headerRow {
  width: 100% !important;
}

/* Disable table outline */
.ReactVirtualized__Grid {
  outline: none;
}

.ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
  margin-right: 0 !important;
  padding-right: 10px !important;
}

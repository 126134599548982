.select-box {
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
}

.row {
    display: flex;
    flex-direction: row;
}

.loading-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.overview {
  width: 100%;
  flex: 1 0 auto;
  margin-top: 1em;
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.overview-header {
  width: 100%;
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.overview-header .title {
  margin-left: 16px;
  margin-bottom: 0.6em;
}

.overview-header .right {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.overview-header .right .auto-refresh {
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-header .right .refresh-button {
  margin-right: 1em;
}

.details-button-cell {
  max-width: 4em;
  padding: 0;
}

.overview-table {
  width: 100%;
  flex: 1 0 auto;
}

.link {
  display: inline-block;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.duration-plots-table {
    overflow-y: hidden;
}

.duration-plots-table .duration-plots-table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.duration-plots-table .duration-plots-table-row .duration-plots-table-row-name {
    padding: 1em;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.cancel-jobs-cell-success-job-id {
  width: 40% !important;
}

.cancel-jobs-cell-success-job-set {
  width: 40% !important;
}

.cancel-jobs-cell-success-time {
  width: 20% !important;
}

.cancel-jobs-cell-failure-job-id {
  width: 30% !important;
}

.cancel-jobs-cell-failure-job-set {
  width: 20% !important;
}

.cancel-jobs-cell-failure-state {
  width: 12% !important;
}

.cancel-jobs-cell-failure-time {
  width: 13% !important;
}

.cancel-jobs-cell-failure-error {
  width: 25% !important;
}

.lookout-table-container {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.lookout-table {
  width: 100%;
  table-layout: fixed;
}

.success-header {
  background-color: var(--success-dark) !important;
}

.success {
  background-color: var(--success-light) !important;
}

.failure-header {
  background-color: var(--failure-dark) !important;
}

.failure {
  background-color: var(--failure-light);
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 5px;
    padding-right: 5px;
}

.groupByElement {
    align-self: stretch;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 5px;

    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: #bdbdbd;
}

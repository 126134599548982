.reprioritize-jobs-cell-success-job-id {
  width: 40% !important;
}

.reprioritize-jobs-cell-success-job-set {
  width: 40% !important;
}

.reprioritize-jobs-cell-success-time {
  width: 20% !important;
}

.reprioritize-jobs-cell-failure-job-id {
  width: 25% !important;
}

.reprioritize-jobs-cell-failure-job-set {
  width: 25% !important;
}

.reprioritize-jobs-cell-failure-priority {
  width: 10% !important;
}

.reprioritize-jobs-cell-failure-time {
  width: 15% !important;
}

.reprioritize-jobs-cell-failure-error {
  width: 25% !important;
}

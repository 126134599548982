.job-details {
    margin-top: 1em;
}

.job-details-header {
    padding-top: 0.66em;
    padding-bottom: 0.66em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.job-details-header .title {
    margin-left: 16px;
    margin-bottom: 0.6em;
}

.job-details-header .refresh-button {
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details-content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.details-table-container {
    width: 100%;
    table-layout: fixed;
}

.field-label {
    width: 30%;
    font-weight: bold !important;
}

.field-value {
    width: 60% !important;
    white-space: normal;
    word-wrap: break-word;
}

.copy {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    width: 10% !important;
}

.error-message {
    font-family: monospace !important;
    background-color: #f9f2f4;
    color: #c7254e !important;
}

.details-yaml-container {
    margin: 1em 1em 2em 1em;
}

.details-yaml {
    width: 100%;
    white-space: pre-wrap;
    font-family: monospace;
    word-wrap: break-word;
}

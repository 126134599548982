.reprioritize-jobs-cell-job-id {
  width: 30% !important;
}

.reprioritize-jobs-cell-job-set {
  width: 30% !important;
}

.reprioritize-jobs-cell-state {
  width: 13% !important;
}

.reprioritize-jobs-cell-priority {
  width: 13% !important;
}

.reprioritize-jobs-cell-time {
  width: 14% !important;
}

.search-header {
    width: 100%;
    padding: 0 1em 0 0;
    text-transform: none;
}

.search-header-text-field {
    width: 100%;
}

.search-header-text-field-input {
    font-size: 14px !important;
}

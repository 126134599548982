.job-table-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid lightgrey;
}

.job-table-header .left {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.job-table-header .right {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.job-table-header .left .title {
  margin-bottom: 0.5em;
}

.job-table-header .left {
  margin-left: 1em;
}

.job-table-header .right .clear {
  margin-right: 1em;
}

.job-table-header .right .auto-refresh {
  margin-right: 1em;
}

.job-table-header .right .refresh {
  margin-right: 1em;
}

.job-table-header .right .reprioritize-jobs {
  margin-right: 4em;
}

.job-table-header .right .cancel-jobs {
  margin-right: 4em;
}

.job-table-header .right .select-columns {
  margin-right: 4em;
}

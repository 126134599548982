.previous-runs-title {
    margin-top: 2em;
    margin-left: 16px;
    width: 100%;
}

.previous-runs {
    margin-bottom: 1em;
    width: 85%;
    align-self: center;
}

.nested-run {
    margin: 1em;
}

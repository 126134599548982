.cancel-jobs-cell-job-id {
  width: 40% !important;
}

.cancel-jobs-cell-job-set {
  width: 32% !important;
}

.cancel-jobs-cell-state {
  width: 13% !important;
}

.cancel-jobs-cell-time {
  width: 15% !important;
}

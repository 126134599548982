.job-logs {
  white-space: pre-wrap;
  max-height: 800px;
  font-family: monospace;
}

.job-logs-options {
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;
  align-items: center;
}

.job-logs-option {
  flex: 0 1 auto;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.job-sets {
    flex: 1 0 auto;
    width: 100%;
    display: flex !important;
    margin-top: 1em;
    flex-direction: column;
}

.job-sets-header {
    width: 100%;
    flex: 0 1 auto;
    margin-top: 0.66em;
    margin-bottom: 0.66em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.job-sets-header .title {
    margin-left: 16px;
    margin-right: 1em;
}

.job-sets-header .job-sets-params {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1em;
    margin-right: 1em;
}

.job-sets-header .job-sets-actions {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.job-sets-header .job-sets-actions .auto-refresh {
    margin-right: 1em;
}

.job-sets-header .job-sets-actions .cancel-button {
    margin-right: 3em;
}

.job-sets-header .job-sets-actions .reprioritize-button {
    margin-right: 3em;
}

.job-sets-field {
    min-width: 7em;
    max-width: 11em;
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.job-sets-header .refresh-button {
    margin-right: 1em;
}

.job-sets-content {
    width: 100%;
    flex: 1 0 auto;
}

.link {
    display: inline-block;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.MuiContainer-root {
    width: 100% !important;
}

.sidebarLogsTabContainer {
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.logsHeader {
  flex: 0 0 auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.logOption {
  flex: 1 1 30%;
  padding: 0 5px 0 5px;
}

.gutter {
  flex: 0 0 40px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.errorMessage {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: pre-wrap;
  font-family: monospace;
  word-wrap: break-word;
  overflow-y: auto;
  background-color: #ffcdd2;
  border-radius: 5px;
  padding: 0 5px 0 5px;
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0 0.5em 0;
}

.logView {
  width: 100%;
  white-space: pre-wrap;
  font-family: monospace;
  word-wrap: break-word;
  margin-top: 5px;
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  overflow-y: auto;
  overflow-x: auto;
}

.emptyLogView {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 0;
}

.timestamp {
  background-color: #e0e0e0;
  margin-right: 10px;
}

.didNotRun {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.state {
  flex: 1 0 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

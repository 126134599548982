.duration-tooltip {
    font-size: 12px;
}

.duration-tooltip .cell-left {
    padding-right: 10px;
}

.duration-tooltip .cell-right {
    padding-left: 10px;
}

.default-header-cell {
    width: 100%;
    padding: 0 1em 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: none;
    font-weight: normal;
}
